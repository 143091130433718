import React from "react"
import styled from "styled-components"
import { Container, Row, Col, Button } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 750px;
  padding: 30px 0 60px 0;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  text-align: center;
  padding-bottom: 30px;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
`
const PressKitButton = styled(Button)`
  color: #489786;
  border: 2px solid #489786;
  border-radius: 30px;
  padding: 6px 26px;
  margin: 30px 20px 0 0;

  @media only screen and (max-width: 767px) {
    padding: 6px 12px;
    margin: 20px 10px 0 0;
  }
  @media only screen and (max-width: 619px) {
    margin-top: 10px;
  }
`

export default () => {
  return (
    <Container fluid>
      <Wrapper>
        <Col>
          <HeadingTwo>Press Kit</HeadingTwo>

          <ParagraphTwo>
            Savor helps people to create a healthier relationship with food. Our
            clean interface and empathetic approach equip users to confront
            their issues with eating and body image. Guided journaling prompts,
            developed by a certified holistic nutritionist, inspire learning and
            healing at a comfortable, personalized pace.
          </ParagraphTwo>

          <ParagraphTwo>
            With Savor, there’s no calorie counting, photo documentation, or
            obsessive food tracking. Instead, we focus on the deeply-held
            stories that drive decisions and behaviors. The result is a more
            sustainable approach to eating, based on emotional wellbeing and
            self-acceptance.
          </ParagraphTwo>

          <div class="presskit-pattern-wrapper"></div>

          <ParagraphTwo>
            Below you'll find product overviews tailored for different audiences, plus print-ready assets, including photos, logos, and screenshots. View the About section for a general app description.
          </ParagraphTwo>
          <PressKitButton
            outline
            href="https://drive.google.com/drive/folders/1eQnpjC9CeDbj7Y10GPifqfwQshkHqKot"
            target="_blank"
          >
            Download Press Kit
          </PressKitButton>
          <PressKitButton
            outline
            href="https://apps.apple.com/us/app/savor/id1457112445"
            target="_blank"
          >
            Savor iOS
          </PressKitButton>
          <PressKitButton
            outline
            href="https://play.google.com/store/apps/details?id=co.savorfood.android.app"
            target="_blank"
          >
            Savor Android
          </PressKitButton>
          <PressKitButton
            outline
            href="../static/documents/Savor - General Overview.pdf"
          >
            About Savor
          </PressKitButton>
        </Col>
      </Wrapper>
    </Container>
  )
}
