import React from 'react';
import Layout from 'components/Layout/Layout';
import SEO from 'components/Layout/Seo';
import PressKit from 'components/PressKit/PressKit';

const PressKitPage = () => (
  <Layout>
    <SEO title="Press Kit" />
    <PressKit />
  </Layout>
)

export default PressKitPage